import React from 'react';
import {theme} from '../src/themes/default-theme';
import styled from 'styled-components';
import {get} from 'lodash';
import {gql} from 'apollo-boost';

import client from '../src/graphql/client';
import refactorHb from '../src/data-restructure/multimedia/hero-banner';
import respImgQuery from '../src/graphql/queries/resp-img';

import Banner from '../src/components/multimedia/Banner';
import {Cta} from '../src/components/ui';
  
const ErrorContainer = styled.div`

`

const TextContainer = styled.div`
  padding: 27px 54px;
  max-width: 780px;
  margin: 100px auto;
  text-align: center;

	& a {
		color: inherit;
	}

	& .MuiButtonBase-root {
    color: white !important;
    ${theme.breakpoints.up('md')} {
      margin: 15px 20px;
    }
    ${theme.breakpoints.down('sm')} {
      width: 100%;
      text-align: center;
      margin-top: 10px;
    }
	}
	& .strong {
		color: ${theme.palette.vividBlue} !important;
	}

	${theme.breakpoints.down('sm')} {
    margin: 40px auto;
    padding-left: 15px;
    padding-right: 15px;
    text-align: center;
	}
` 

const BodyText = styled.div`
	font-size: 14px;
	font-weight: 400;

	${theme.breakpoints.down('sm')} {
		font-size: 14px;

		ul,
		ol {
			font-size: 14px;
		}
	}
` 

const CtaItem = styled.div`
	display: inline-block;
	margin-right: 30px;
	margin-left: 30px;

	${theme.breakpoints.down('sm')} {
		display: block;
    margin-top: 20px;
    margin-right: 0;
    margin-left: 0;
	}
	
	a {
		min-width: 263px;
		${theme.breakpoints.down('sm')} {
			margin: 15px 20px;
      display: block;
      max-width: 300px;
      margin-left: auto;
      margin-right: auto;
		}
	}
`

const ErrorPage = (props) => {

  return (
  	<ErrorContainer>
      {props.heroBanner &&
        <Banner
          mainHeading="Whoopsies"
          subHeading2="Page cannot be found"
          bgImage={props.heroBanner.bgImage}
          type="mediumBanner"
        />
      }
      <TextContainer>
        <BodyText dangerouslySetInnerHTML={{ __html: 'Sorry, but the page you’re looking for does not exist or has been deleted. Please check the address of the page you were looking for or click below to browse the Jayo range or return to the home page.' }} />
        <CtaItem>
          <Cta
            href={'/range'}
            inline 
            outline={false}
          >
            Browse the range
          </Cta>
          <Cta
            className={'strong'}
            href={'/'} 
            inline 
            outline={true}
          >
            Return to home
          </Cta>
        </CtaItem>
      </TextContainer>
    </ErrorContainer>
  )
}
  
export async function getStaticProps() {
  const GET_PAGE_CONTEXT = gql`
    query GET_FAQ_PAGE {
      pageBy(uri: "faqs") {
        acf_content_builder {
          contentBuilder {
            ... on Page_AcfContentBuilder_ContentBuilder_FcSingleHeroBanner {
              fieldGroupName
              shbHeadingText
              shbBannerImage {
                ${respImgQuery}
              }
            }
          }
        }
      }
    }
  `

  const contextProps = await client.query({
    query: GET_PAGE_CONTEXT
  });

  if (contextProps) {
    const context = get(contextProps, 'data.pageBy', null);

    const hbData = get(context, 'acf_content_builder.contentBuilder', null);
    const heroBanner = hbData ? refactorHb(hbData[0], 'shb') : null;

    const data = {
      heroBanner
    }

    return {
      props: {
        ...data,
      }
    }
  } else { 
    return { props: {} }
  }
}
  
export default ErrorPage